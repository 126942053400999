.navigation-root {
  width: 280px;
  background-color: #2f394d;

  &__head {
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 30px;

    &__link {
      text-decoration: none;
      color: white;
      font-weight: bold;
      font-size: 16px;
      line-height: 1;

      &__version {
        font-size: 9px;
      }

      &:hover {
        text-decoration: underline;
        color: white;
      }
    }
  }

  &__nav {
    color: white;

    & ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    &__link {
      display: block;
      text-decoration: none;
      color: white;
      font-weight: bold;
      padding: 16px 30px;
      font-size: 12px;

      &:hover {
        color: white;
        background-color: rgba(355, 355, 355, 0.1);
      }

      &__active {
        background-color: rgba(355, 355, 355, 0.2);
      }

      &__title {
        margin-left: 10px;
      }

      &__icon {
        display: inline-block;
        width: 26px;
      }
    }
  }
}
