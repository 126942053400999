.signin-root {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2f394d;

  & h1 {
    margin-bottom: 25px;
    color: white;
  }

  &__loginbox {
    width: 100%;
    max-width: 620px;
    padding: 40px;
    background-color: white;
    border-radius: 20px;
  }
}
