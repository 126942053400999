.header-root {
  height: 60px;
  background-color: #ffffff;
  border-bottom: 1px solid lightgray;

  &__user {
    &__name {
      margin-right: 10px;
    }
  }
}
